import * as React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import logo from "../styles/yoda-logo.png";
import { useAuth } from "./Context";

const Layout: React.FC<RouteComponentProps> = ({ children }) => {
  const data = useAuth();

  return (
    <span>
      <span className="bg text-white text-center d-flex flex-column">
        <nav className="navbar navbar-light justify-content-between mb-5">
          <Link className="navbar-brand text-white" to="/">
            <img
              src={logo}
              width="53"
              height="30"
              className="d-inline-block align-top"
              title="Que la force soit avec toi !"
              alt=""
            />
            Padawan <i className="small">by ViaRézo</i>
          </Link>
          {data.isAdmin && (
            <Link to="/admin" className="btn btn-danger">
              Administration
            </Link>
          )}
          <Link to="/user" className="btn btn-secondary">
            {" "}
            Changer mes identfiants
          </Link>
        </nav>
        <div className="d-flex align-items-center flex-grow-1">
          <div className="flex-column flex-grow-1 justify-content-center">
            {children}
          </div>
        </div>
      </span>
    </span>
  );
};
export default withRouter(Layout);
