import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import DockerModal from "./DockerModal";
import { useAuth } from "./Context";
import useFetch from "../hooks/useFetch";

const Home: React.FC<RouteComponentProps> = () => {
  const user = useAuth();
  const [dockerImages, loading, error] = useFetch("/api/docker");
  console.log('errordocker', error, dockerImages)
  const [container, , , updateContainer] = useFetch(
    "/api/docker/container"
  );
  const [show, setShow] = useState(false);
  const [creationError, setCreationError] = useState();
  console.log("container", container);

  const createContainer = () => {
    fetch("/api/docker/start", {
      method: "POST",
      headers: { "Content-Type": "application/json", "CSRF-Token": user._csrf },
    })
      .then((response) => {
        updateContainer();
      })
      .catch((error) => setCreationError(error));
  };

  return (
    <span>
      {user && user.dockerPasswordSet && user.dockerLogin ? (
        <span>
          <div className="row no-margin justify-content-center">
            <h4 className="m-1">
              Bonjour <samp>{user.dockerLogin}</samp> !
            </h4>
          </div>
          <div className="row no-margin justify-content-center">
            <h5>Pour commencer ton entraînement un conteneur créer tu dois…</h5>
          </div>
          {container && container.port ? (
            <>
              <div className="row no-margin justify-content-center">
                <h6>
                  {" "}
                Pour te connecter tape: ssh {user.dockerLogin}
                @padawan.viarezo.fr -p {container.port}
                </h6>
              </div>
              <div className="row no-margin justify-content-center">
                <span className="recreateButton" onClick={createContainer}> Recréer le conteneur</span>
              </div>
            </>
          ) : (
              <div className="row no-margin justify-content-center">
                { user.selectedDocker ?
                  <span className="selectButton" onClick={createContainer}>
                    {" "}
                Créer un conteneur
              </span> : " "}
              </div>
            )}
          {creationError && <p>Error on creation</p>}
          {user.selectedDocker ? (
            <>
              <div className="row no-margin justify-content-center">
                <h5>
                  Le docker sélectionné est {user.selectedDocker.dockerName}
                </h5>
              </div>
              <div className="row no-margin justify-content-center">

                <button className="selectButton" onClick={() => setShow(true)}>
                  Changer d'image Docker
              </button>
              </div>
            </>
          ) : (
              <>
                <div className="row no-margin justify-content-center">
                  <h5>Aucun Docker selectionné</h5>
                </div>
                <div className="row no-margin justify-content-center">
                  <span className="handle"></span>
                  <span className="selectButton" onClick={() => setShow(true)}>
                    Selectionner une image Docker
                </span>
                </div>
              </>
            )}
        </span>
      ) : (
          <span>
            <div className="row no-margin justify-content-center">
              <h4 className="p-5">
                Bonjour jeune padawan, pour commencer ton entraînement, un pseudo
                et un mot de passe choisir tu dois.
            </h4>
            </div>
            <div className="row no-margin justify-content-center">
              <a href="/user" className="btn btn-primary">
                Choisir
            </a>
            </div>
          </span>
        )}
      <DockerModal
        dockerImages={dockerImages}
        loading={loading}
        error={error}
        user={user}
        show={show}
        setShow={setShow}
      ></DockerModal>
    </span>
  );
};
export default withRouter(Home);
