import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

const Unauthorized: React.FC<RouteComponentProps> = () => {
  return (
    <span>
      <div className="row no-margin justify-content-center">
        <div className="col-ms-3 rounded p-2 m-5 text-dark form-background">
          <h3>Tu n'es pas autorisé à acceder à cette page</h3>
        </div>
      </div>
    </span>
  );
};

export default withRouter(Unauthorized);
