import React, { useState } from "react";
import { useAuth } from "./Context";

const UserForm: React.FC = () => {
  const [creds, setCreds] = useState({ dockerLogin: "", dockerPassword: "" });
  const [errorPassword, setErrorPassword] = useState<string | null>(null);
  const [errorLogin, setErrorLogin] = useState<string | null>(null);
  const [errorSubmit, setErrorSubmit] = useState<string | null>(null);
  const user = useAuth();

  const handleSubmit = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (creds.dockerPassword.length <= 7)
      setErrorPassword("Votre mot de passe doit faire au moins 8 caractères");
    if (creds.dockerLogin.length <= 0)
      setErrorLogin("Votre nom d'utilisateur ne peut être vide");
    if (creds.dockerPassword.length > 7 && creds.dockerLogin.length > 0) {
      try {
        fetch("/api/user/setCreds", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "CSRF-Token": user._csrf,
          },
          body: JSON.stringify(creds),
        })
          .then((response) => {
            console.log("response", response);
            window.location.href = "/";
          })
          .catch((error) => console.log("error", error));
      } catch (error) {
        setErrorSubmit(error);
      }
    }
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setCreds({
      ...creds,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };
  return (
    <span>
      <div className="row no-margin justify-content-center">
        <div className="col-ms-3 rounded p-2 m-5 text-dark form-background">
          <h3>Choisis ton pseudo et ton mot de passe</h3>
          <form>
            <input type="hidden" name="_csrf" value="{{csrfToken}}" />
            <div className="form-group">
              <label htmlFor="inputLogin">pseudo ([a-zA-Z0-9])</label>
              <input
                name="dockerLogin"
                value={creds.dockerLogin}
                onChange={handleChange}
                className="form-control"
                id="inputLogin"
              />
              {errorLogin && <p className="form-error"> {errorLogin}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="inputPassword">
                mot de passe du conteneur (+ 7 caractères)
              </label>
              <input
                name="dockerPassword"
                value={creds.dockerPassword}
                onChange={handleChange}
                type="password"
                className="form-control"
                id="inputPassword"
              />
              {errorPassword && <p className="form-error"> {errorPassword}</p>}
            </div>
            <div className="form-group">
              <input
                className="btn btn-primary"
                type="submit"
                value="Soumettre"
                onClick={handleSubmit}
              />
              {errorSubmit && <p className="form-error"> {errorSubmit}</p>}
            </div>
          </form>
        </div>
      </div>
    </span>
  );
};

export default UserForm;
