import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { IDockerInfos, IDockerModal } from "../interfaces";

const DockerModal: React.FC<IDockerModal> = ({
  loading,
  error,
  show,
  setShow,
  user,
  dockerImages,
}) => {
  const [selectedImage, setSelectedImage] = useState<undefined | number>(
    undefined
  );

  useEffect(() => {
    if (show && user && user.selectedDocker)
      setSelectedImage(user.selectedDocker.dockerId);
  }, [show, user]);

  const handleSubmit = async () => {
    const params = {
      method: "POST",
      body: JSON.stringify({ dockerId: selectedImage }),
      headers: {
        "Content-Type": "application/json",
        "CSRF-Token": user._csrf,
      },
    };
    console.log(params);
    await fetch("/api/user/setDocker", params);
    user.fetchUserData();
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>Choisissez une image Docker</Modal.Header>
      <Modal.Body>
        {loading ? (
          <p>Chargement en cours, merci de patienter</p>
        ) : error ? (
          <p> {error} </p>
        ) : (
          dockerImages &&
          dockerImages.map((image: IDockerInfos) => (
            <Button
              key={image.dockerId}
              variant="secondary"
              active={selectedImage === image.dockerId}
              onClick={() => setSelectedImage(image.dockerId)}
            >
              {image.dockerName}
            </Button>
          ))
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSubmit}>
          Valider mon choix
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default DockerModal;
