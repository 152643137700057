import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { INewDockerModal } from "../interfaces";

const NewDockerModal: React.FC<INewDockerModal> = ({ show, setShow, user }) => {
  const [imageInfos, setImageInfos] = useState({
    dockerName: "",
    dockerUri: "",
  });

  const handleSubmit = (event: React.FormEvent<HTMLInputElement>) => {
    console.log("submit");
    event.preventDefault();
    try {
      console.log(user._csrf);
      fetch("/api/docker/new", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "CSRF-Token": user._csrf,
        },
        body: JSON.stringify(imageInfos),
      })
        .then((response) => console.log("response", response))
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setImageInfos({
      ...imageInfos,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  return (
    <Modal onHide={() => setShow(false)} show={show}>
      <Modal.Header closeButton>Choisissez une image Docker</Modal.Header>
      <Modal.Body>
        <form method="POST" action="/api/docker/pull">
          <ul className="form-docker">
            <li className="form-row">
              <label className="item" htmlFor=" inputLogin">Nom de l'image</label>
              <input className="item" name="dockerName" type="text" onChange={handleChange}></input>
            </li>
            <li className="form-row">
              <label className="item" htmlFor="inputLogin">URI de l'image Docker</label>
              <input className="item" name="dockerUri" type="text" onChange={handleChange}></input>
            </li>
            <li>
              <input
                className="btn btn-primary"
                type="submit"
                value="Soumettre"
                onClick={handleSubmit}
              />
            </li>

          </ul>



        </form>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant='success' onClick={handleSubmit}>Valider mon choix</Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default NewDockerModal;
