import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { useAuth } from "./Context";
import NewDockerModal from "./NewDockerModal";

const Admin: React.FC<RouteComponentProps> = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [dockers, , , updateDockers] = useFetch("/api/docker");
  const user = useAuth();

  console.log(dockers)
  const handleDelete = (imageName: string) => {
    console.log(`Delete ${imageName}`)
    fetch('/api/docker/', {
      method: "DELETE",
      headers: { "Content-Type": "application/json", "CSRF-Token": user._csrf },
      body: JSON.stringify({ imageName }),
    }).then(() => updateDockers()

    )
  }

  return (
    <span>
      <div className="row no-margin justify-content-center">
        <div>
          <ul className="docker-list">
            {dockers &&
              dockers.map((image: any) => (
                <li key={image.dockerId} className="docker-item">
                  <span className="name">{image.dockerName}</span>
                  <span className="delete-button" onClick={() => { handleDelete(image.dockerUri) }}></span>
                </li>
              ))}
          </ul>
          <Button
            onClick={() => setShowAddForm(true)}
            className="btn btn-secondary"
          >
            Ajouter une image
          </Button>
        </div>
      </div>
      <NewDockerModal show={showAddForm} setShow={setShowAddForm} user={user} />
    </span>
  );
};

export default withRouter(Admin);
