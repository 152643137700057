import { useCallback, useEffect, useState } from "react";

const useFetch = (
  url: string,
): [any, boolean, any, Function] => {
  const [data, setData] = useState<any>();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(false);
  const updateData = useCallback(async () => {
    console.log(`fetching ${url}`);

    setLoading(true);
    setError(false);
    try {
      const fetchedData = await fetch(url);
      console.log("fetchedData", fetchedData);
      if (fetchedData.ok) {
        const fetched = fetchedData
          .json()
          .then((response) => response) // If the response is on JSON format
          .catch((error) => fetchedData.body); // If reponse is not on JSON format

        setData(await fetched);
      } else {
        const fetched = fetchedData
          .json()
          .then((response) => response) // If the response is on JSON format
          .catch((error) => error); // If reponse is not on JSON format

        setError(await fetched);
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [url]);
  useEffect(() => {
    updateData();
  }, [updateData, url]);

  return [data, loading, error, updateData];
};
export default useFetch;
