import React from "react";
import useFetch from "./hooks/useFetch";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Layout from "./components/Layout";
import "./styles/style.css";
import "./styles/bootstrap/css/bootstrap.css";
import "./styles/bootstrap/css/bootstrap-grid.css";
import "./styles/yoda-logo.png";
import Home from "./components/Home";
import User from "./components/User";
import { AuthContext } from "./components/Context";
import Unauthorized from "./components/Unauthorized";
import Admin from "./components/Admin";
import { Spinner } from "react-bootstrap";

function App() {
  const [data, loading, error, fetchUserData] = useFetch("api/user");

  document.title = "PadawanV3";

  // If user is not logged redirect to login page
  console.log(loading, "loading");
  console.log(data, "data");
  console.log("error", error);
  if (error && error.redirect) window.location.href = error.redirect;

  return error ? (
    <p> {error.message}</p>
  ) : (
      <span>
        <AuthContext.Provider value={{ ...data, fetchUserData }}>
          <Router>
            <Layout>
              {loading === true ? (
                <Spinner animation="border" variant="light" />
              ) :
                <Switch>
                  <Route strict exact path="/" component={Home} />
                  <Route exact strict path="/user" component={User} />
                  <Route
                    exact
                    strict
                    path="/admin"
                    render={() =>
                      data && data.isAdmin ? <Admin /> : <Unauthorized />
                    }
                  />
                </Switch>}
            </Layout>
          </Router>
        </AuthContext.Provider>
      </span>
    );
}

export default App;
